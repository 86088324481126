export default {
  msg_default_website_title: 'Backoffice',
  msg_default_meta_title: 'Default meta title',
  msg_default_meta_description: 'Default meta description',

  //Pages
  msg_participants_page_title: 'Participants',
  msg_participant_page_title: 'Participant',
  msg_coaches_page_title: 'Coaches',
  msg_coach_page_title: 'Coach',
  msg_sessions_page_title: 'Sessions',
  msg_session_page_title: 'Session',
  msg_groups_page_title: 'Groups',
  msg_group_page_title: 'Group',
  msg_companies_page_title: 'Companies',
  msg_company_page_title: 'Company',
  msg_availability_page_title: 'Availability',
  msg_import_page_title: 'Import',
  msg_stripe_page_title: 'Stripe',
  msg_statistics_page_title: 'Statistics',
  msg_not_found_page_title: 'Not found',
  msg_about_page_title: 'About',
} as const;
