import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ErrorBoundaryFallback } from '@Components/ErrorBoundary';
import GlobalLoader from '@Components/GlobalLoader';

const App = React.lazy(() => import('./App'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const AppInitiator = () => {
  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <Suspense fallback={<GlobalLoader />}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppInitiator;
