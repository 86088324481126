import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { logError } from '@Utils/logger';
import EnTranslations from '@Utils/translations/en';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  saveMissing: true,
  resources: {
    en: {
      translation: EnTranslations,
    },
  },
  missingKeyHandler: (_lngs, _ns, key) => {
    logError(`Missing translation key: ${key}`);
  },
  interpolation: {
    escapeValue: false,
  },
  missingInterpolationHandler: (key) => {
    logError(`Missing translation param: ${key}`);
  },
});

export type MessageKey = keyof typeof EnTranslations;

export const translate = (key: MessageKey, options?: TOptions) => {
  return i18n.t(key, options);
};
