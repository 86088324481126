import React, { useEffect, useRef } from 'react';

import Button, { ButtonVariant } from '@Components/Button';

import styles from './ErrorBoundary.module.scss';

interface FallbackProps {
  error: Error;
  resetError(): void;
}

export const ErrorBoundaryFallback = (props: FallbackProps) => {
  return <ErrorBoundaryFallbackView {...props} />;
};

const ErrorBoundaryFallbackView = ({ error, resetError }: FallbackProps) => {
  const initialPath = useRef(window.location.pathname);

  useEffect(() => {
    if (window.location.pathname !== initialPath.current) {
      resetError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, resetError]);

  return (
    <div className={styles.container}>
      <div>Unexpected error:</div>
      <div>
        <pre>{error.message}</pre>
      </div>
      <div>
        <Button variant={ButtonVariant.Info} onClick={resetError}>
          Please try again
        </Button>
      </div>
    </div>
  );
};
