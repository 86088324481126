import classNames from 'classnames';
import React from 'react';

import Icon, { IconSizes } from '@Components/Icon';

import styles from './GlobalLoader.module.scss';

interface GlobalLoaderProps {
  className?: string;
  local?: boolean;
  centered?: boolean;
}

const GlobalLoader = ({ className, local, centered }: GlobalLoaderProps) => {
  return (
    <div className={classNames(styles.globalLoader, { [styles.local]: local, [styles.centered]: centered }, className)}>
      <Icon icon="spinner" spin={true} size={IconSizes.l} />
    </div>
  );
};

export default GlobalLoader;
