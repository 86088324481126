import classNames from 'classnames';
import React from 'react';

export enum ButtonSize {
  Small = 'sm',
}

export enum ButtonVariant {
  Primary = 'primary',
  Danger = 'danger',
  Info = 'info',
}

export interface ButtonProps {
  children: React.ReactNode;
  type?: 'submit' | 'button';
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  type = 'button',
  variant = ButtonVariant.Primary,
  size = ButtonSize.Small,
  onClick,
  disabled,
  isLoading,
  className,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classNames(`btn btn-${size} btn-${variant} p-1`, className)}
      style={{ minWidth: 55 }} // ToDo: a candidate for refactoring to emotion or css-modules
      onClick={onClick}
    >
      {isLoading ? <i className="fa fa-spinner fa-spin" /> : children}
    </button>
  );
};

export default Button;
